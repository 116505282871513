import React, { useState } from 'react';
import axios from 'axios';
import { FaShoppingCart, FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/header';
import styles from './manual-sale.module.css';

const ManualSale = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderYampiId, setOrderYampiId] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const searchUser = async () => {
    if (!searchTerm) {
      toast.error('Digite um termo para buscar');
      return;
    }

    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATABASE}/search/users?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      
      if (response.data.users && response.data.users.length > 0) {
        setSearchResults(response.data.users);
      } else {
        setSearchResults([]);
        toast.info('Nenhum usuário encontrado');
      }
    } catch (error) {
      toast.error('Erro ao buscar usuários');
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    setSearchResults([]);
    setSearchTerm('');
  };

  const createManualSale = async () => {
    if (!selectedUser?._id || !orderYampiId) {
      toast.error('Preencha todos os campos obrigatórios');
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_DATABASE}/admin/sales/manual`,
        {
          userId: selectedUser._id,
          orderYampiId: parseInt(orderYampiId)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      toast.success('Venda registrada com sucesso!');
      setOrderYampiId('');
      setSelectedUser(null);
      setSearchTerm('');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao registrar venda');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.manualSaleMain}>
      <Header />
      <div className={styles.manualSaleContainer}>
        <h1 className={styles.manualSaleTitle}>
          Registrar Venda Manual
        </h1>
        <p className={styles.manualSaleDescription}>
          Registre uma venda manualmente usando o ID do pedido da Yampi
        </p>

        <div className={styles.salePanel}>
          <div className={styles.searchSection}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                className={styles.input}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Busque por email, CPF, RG, telefone ou redes sociais"
              />
              <button
                className={styles.searchButton}
                onClick={searchUser}
                disabled={searchLoading}
              >
                {searchLoading ? (
                  'Buscando...'
                ) : (
                  <>
                    <FaSearch /> Buscar
                  </>
                )}
              </button>
            </div>

            {searchResults.length > 0 && (
              <div className={styles.searchResults}>
                {searchResults.map((user) => (
                  <div 
                    key={user._id} 
                    className={styles.userResult}
                    onClick={() => selectUser(user)}
                  >
                    <p><strong>Nome:</strong> {user.name}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                  </div>
                ))}
              </div>
            )}

            {selectedUser && (
              <div className={styles.userInfo}>
                <h3>Influenciador Selecionado</h3>
                <p><strong>Nome:</strong> {selectedUser.name}</p>
                <p><strong>Username:</strong> {selectedUser.username}</p>
              </div>
            )}
          </div>

          <div className={styles.orderSection}>
            <div className={styles.inputGroup}>
              <input
                type="number"
                className={styles.input}
                value={orderYampiId}
                onChange={(e) => setOrderYampiId(e.target.value)}
                placeholder="ID do pedido Yampi"
              />
            </div>
          </div>

          <button
            className={styles.registerButton}
            onClick={createManualSale}
            disabled={loading || !selectedUser || !orderYampiId}
          >
            {loading ? (
              'Registrando...'
            ) : (
              <>
                <FaShoppingCart /> Registrar Venda
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualSale; 