import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaSearch, FaDownload, FaCheckCircle  } from 'react-icons/fa';
import * as yup from 'yup';
import styles from './cadastro.module.css';
import zxcvbn from 'zxcvbn';

const Cadastro = () => {
  const { id } = useParams();
  const history = useHistory();
  const [cadastroCompleto, setCadastroCompleto] = useState(false);
  const [step, setStep] = useState(0);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showScrollError, setShowScrollError] = useState(false);
  const [pdfPages, setPdfPages] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dropdownRef = useRef(null);
  const contractViewerRef = useRef(null);

  const Spinner = () => (
    <svg className={styles.spinner} viewBox="0 0 50 50">
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  );  

  const disableField = (fieldName) => {
    const elements = document.getElementsByName(fieldName);
    if (elements.length > 0) {
      elements[0].disabled = true;
    }
  };

  const checkExistingUserData = async (fields) => {
    const fieldsToCheck = fields.filter(field => ['rg', 'cpf', 'email', 'phone', 'instagram', 'facebook', 'tiktok', 'youtube'].includes(field));
    
    const socialMediaPrefixes = {
      instagram: 'https://instagram.com/',
      facebook: 'https://facebook.com/',
      youtube: 'https://youtube.com/@',
      tiktok: 'https://tiktok.com/@',
    };
  
    for (const field of fieldsToCheck) {
      let value = watch(field);
      if (value) {
        if (['instagram', 'facebook', 'youtube', 'tiktok'].includes(field)) {
          value = socialMediaPrefixes[field] + value;
        }
  
        try {
          const response = await axios.post(`${process.env.REACT_APP_DATABASE}/search/exists-user`, { term: value });
          if (response.data.found) {
            setError(field, {
              type: 'manual',
              message: `Este ${getLabel(field)} já está cadastrado na plataforma.`
            });
            return false;
          }
        } catch (error) {
          console.error(`Error checking existing user data for ${field}:`, error);
        }
      }
    }
    return true;
  };
  

  const isValidCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    const digits = cpf.split('').map(x => parseInt(x));
    for (let j = 0; j < 2; j++) {
      let sum = 0;
      for (let i = 0; i < 9 + j; i++) {
        sum += digits[i] * (10 + j - i);
      }
      let check = 11 - (sum % 11);
      if (check === 10 || check === 11) check = 0;
      if (check !== digits[9 + j]) return false;
    }
    return true;
  };

  const isValidSocialMediaUsername = (username, platform) => {
    const patterns = {
      instagram: /^[a-zA-Z0-9._]{1,30}$/,
      facebook: /^[a-zA-Z0-9._-]{3,50}$/i,
      youtube: /^[a-zA-Z0-9._-]{2,30}$/,
      tiktok: /^[a-zA-Z0-9._]{2,24}$/,
    };
    return patterns[platform].test(username);
  };

  const isValidRG = (rg) => {
    return /^\d{7,14}$/.test(rg);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const PasswordStrengthMeter = ({ strength }) => {
    const getColor = () => {
      switch (strength.score) {
        case 0: return '#dc3545';
        case 1: return '#ffc107';
        case 2: return '#fd7e14';
        case 3: return '#20c997';
        case 4: return '#68b43a';
        default: return '#e9ecef';
      }
    };
  
    const getText = () => {
      switch (strength.score) {
        case 0: return 'Muito fraca';
        case 1: return 'Fraca';
        case 2: return 'Média';
        case 3: return 'Forte';
        case 4: return 'Muito forte';
        default: return '';
      }
    };
  
    return (
      <div className={styles.strengthMeter}>
        <div 
          className={styles.strengthBar} 
          style={{ 
            width: `${(strength.score + 1) * 20}%`, 
            backgroundColor: getColor() 
          }}
        ></div>
        <span className={styles.strengthText} style={{ color: getColor() }}>
          {getText()}
        </span>
      </div>
    );
  };

  const schema = yup.object().shape({
    name: yup.string().required('Nome completo é obrigatório')
      .min(3, 'Nome deve ter pelo menos 3 caracteres')
      .test('full-name', 'Forneça nome e sobrenome', value => value.trim().split(' ').length > 1),
    birthDate: yup.date()
      .typeError('Preencha a data de nascimento')
      .required('Data de nascimento é obrigatória')
      .max(new Date(), 'Data inválida')
      .min(new Date(1900, 0, 1), 'Data inválida'),
    gender: yup.string().oneOf(['masculino', 'feminino', 'outro'], 'Gênero inválido').required('Gênero é obrigatório'),
    rg: yup.string().required('RG é obrigatório').test('valid-rg', 'RG inválido', isValidRG),
    cpf: yup.string().required('CPF é obrigatório').test('valid-cpf', 'CPF inválido', isValidCPF),
    email: yup.string().required('E-mail é obrigatório').test('valid-email', 'E-mail inválido', isValidEmail),
    phone: yup.string().matches(/^(\d{2})(9?\d{8})$/, 'Telefone inválido').required('Telefone é obrigatório'),
    password: yup
      .string()
      .min(6, 'A senha deve ter pelo menos 6 caracteres')
      .test('password-strength', 'A força da senha deve ser forte ou superior', (value) => {
        const passwordStrength = zxcvbn(value);
        return passwordStrength.score >= 3;
      })
      .required('Senha é obrigatória'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .required('Confirmação de senha é obrigatória'),
    instagram: yup.string(),
    facebook: yup.string(),
    youtube: yup.string(),
    tiktok: yup.string(),
    bankID: yup.string().required('Banco é obrigatório'),
    bankAgency: yup.string().required('Agência bancária é obrigatória'),
    bankAccount: yup.string().required('Conta bancária é obrigatória'),
    pixKey: yup.string().required('Chave PIX é obrigatória'),
  })

  const fetchBanksByName = useCallback(async (name) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/search/banks`, {
        params: { name }
      });
      setFilteredBanks(response.data.banks || []);
    } catch (error) {
      console.error('Erro ao buscar bancos:', error);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredBanks([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      fetchBanksByName(searchTerm);
    } else {
      setFilteredBanks([]);
    }
  }, [searchTerm, fetchBanksByName]);  

  const { register, handleSubmit, formState: { errors }, trigger, watch, setValue, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const password = watch('password');
  useEffect(() => {
    if (password) {
      const strength = zxcvbn(password);
      setPasswordStrength(strength);
    } else {
      setPasswordStrength(null);
    }
  }, [password]);

  useEffect(() => {
    const verifyId = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DATABASE}/auth/verify/${id}`);

        const {name, instagram, tiktok, isInfluencer } = response.data;

        if (response.data.hasOwnProperty("isInfluencer")) setIsInfluencer(isInfluencer);

        const extractUsername = (url, platform) => {
          if (!url) return '';
          
          let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
          cleanUrl = cleanUrl.split('?')[0];
          
          const patterns = {
            instagram: /^(?:instagram\.com\/|)([^\/]+)/,
            tiktok: /^(?:tiktok\.com\/(@|))[^\/]+/
          };
          
          const match = cleanUrl.match(patterns[platform]);
          return match ? match[1] : '';
        };

        if(isInfluencer) {
          if (name) {
            setValue('name', name);
            disableField('name');
          }
          if (instagram) {
            const username = extractUsername(instagram, 'instagram');
            setValue('instagram', username);
          }
          if (tiktok) {
            const username = extractUsername(tiktok, 'tiktok');
            setValue('tiktok', username);
          }
        }


      } catch (error) {
        toast.error('Só é possível entrar na plataforma através de um convite.');
        history.push('/login');
      }
    };

    verifyId();
  }, [id, history]);

  const onSubmit = useCallback(async (data) => {
    setIsSubmitting(true);

    const completeData = {
      ...data,
      instagram: data.instagram ? `https://instagram.com/${data.instagram}` : undefined,
      facebook: data.facebook ? `https://facebook.com/${data.facebook}` : undefined,
      youtube: data.youtube ? `https://youtube.com/@${data.youtube}` : undefined,
      tiktok: data.tiktok ? `https://tiktok.com/@${data.tiktok}` : undefined,
      bankID: selectedBank ? selectedBank._id : undefined
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/auth/register`, {
        ...completeData,
        influencerPipelineId: isInfluencer ? id : undefined,
        indicatorId: !isInfluencer ? id : undefined,
      });
      if (response.data.userId) {
        // Dispara o evento de lead do Facebook com dados detalhados
        if (window.fbq) {
          window.fbq('track', 'Lead', {
            content_name: data.name,
            content_category: isInfluencer ? 'Influencer' : 'Regular',
            value: 1.00,
            currency: 'BRL',
            status: 'complete',
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            social_media: {
              instagram: data.instagram,
              facebook: data.facebook,
              youtube: data.youtube,
              tiktok: data.tiktok
            }
          });
        }
        setCadastroCompleto(true);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao realizar cadastro');
    } finally {
      setIsSubmitting(false);
    }

  }, [id, isInfluencer, history, termsAccepted, selectedBank]);

  const steps = [
    { title: 'Informações Pessoais', fields: ['name', 'birthDate', 'gender'] },
    { title: 'Documentos', fields: ['rg', 'cpf'] },
    { title: 'Contato', fields: ['email', 'phone'] },
    { title: 'Segurança', fields: ['password', 'confirmPassword'] },
    { title: 'Redes Sociais', fields: ['instagram', 'facebook', 'youtube', 'tiktok'] },
    { title: 'Informações Bancárias', fields: ['bankID', 'bankAgency', 'bankAccount', 'pixKey'] },
    { title: 'Termos e Condições', fields: [] },
  ];

  const nextStep = async () => {
    if (step < steps.length - 1) {
      const fields = steps[step].fields;
      let isStepValid = await trigger(fields);
  
      if (isStepValid) {
        if (step === 5 && !selectedBank) {
          setError('bankID', { type: 'manual', message: 'Banco é obrigatório' });
          return;
        }
  
        if (step === 4) {
          const socialMediaValues = socialFields.map(field => watch(field));
          console.log('Valores das redes sociais:', socialMediaValues);
          
          // Verifica se pelo menos uma rede social foi preenchida
          if (!socialMediaValues.some(value => value && value.trim() !== '')) {
            setError('instagram', {
              type: 'manual',
              message: 'É obrigatório preencher pelo menos uma rede social'
            });
            return;
          }

          // Valida individualmente cada rede social preenchida
          for (const field of socialFields) {
            const value = watch(field);
            if (value && value.trim() !== '') {
              if (!isValidSocialMediaUsername(value, field)) {
                setError(field, {
                  type: 'manual',
                  message: `Username do ${getLabel(field)} inválido`
                });
                return;
              }
            }
          }
        }
  
        const isDataUnique = await checkExistingUserData(fields);
        if (!isDataUnique) {
          return;
        }
  
        setStep((prev) => prev + 1);
      } else {
        // Exibe os erros no console para debug
        console.log('Erros de validação:', errors);
      }
    } else {
      if (!termsAccepted) {
        toast.error('É necessário aceitar os termos e condições para finalizar o cadastro.');
      } else {
        const result = await trigger();        
        if (result) {
          const formData = watch();
          onSubmit(formData);
        } else {
          toast.error('Por favor, verifique todos os campos do formulário.');
          Object.keys(errors).forEach(field => {
            console.log(`Error in field ${field}:`, errors[field].message);
          });
        }  
      }
    }
  };

  const prevStep = () => setStep(prev => prev - 1);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextStep();
      } else if (event.key === 'ArrowLeft' && step > 0) {
        prevStep(); 
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (step === steps.length - 1) {
          setShowContractModal(true);
        } else {
          nextStep();
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [step, nextStep, prevStep, steps.length]);



  const renderField = (field) => {
    switch (field) {
      case 'gender':
        return (
          <select {...register(field)} className={styles.input} defaultValue={""}>
            <option value="" disabled>Selecione o gênero</option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
            <option value="outro">Outro</option>
          </select>
        );
      case 'birthDate':
        return <input type="date" {...register(field)} className={styles.input} placeholder={getPlaceholder(field)} />;
      case 'password':
        return (
          <div>
            <input 
              type="password" 
              {...register(field)} 
              className={styles.input} 
              placeholder="Mínimo 6 caracteres" 
              autoComplete="new-password" 
            />
            {passwordStrength && <PasswordStrengthMeter strength={passwordStrength} />}
          </div>
        );    
      case 'confirmPassword':
        return <input type="password" {...register(field)} className={styles.input} placeholder={field === 'password' ? 'Mínimo 6 caracteres' : 'Digite novamente sua senha'} autoComplete="new-password" />;
      case 'bankID':
        return (
          <div className={styles.bankFieldContainer}>
            <div className={styles.bankInputWrapper}>
              <FaSearch className={styles.searchIcon} />
              <input
                type="text"
                className={`${styles.input} ${styles.bankInput}`}
                placeholder="Digite o nome do banco"
                value={selectedBank ? selectedBank.bankName : searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setSelectedBank(null);
                  setValue('bankID', '');
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFilteredBanks([]);
                  }, 200);
                }}
                autoComplete="off"
                disabled={!!selectedBank}
              />
              {selectedBank && (
                <button
                  type="button"
                  className={styles.resetButton}
                  onClick={() => {
                    setSelectedBank(null);
                    setSearchTerm('');
                    setValue('bankID', '');
                  }}
                >
                  ✕
                </button>
              )}
            </div>
            {filteredBanks.length > 0 && !selectedBank && (
              <ul className={styles.dropdown} ref={dropdownRef}>
                {filteredBanks.map((bank) => (
                  <li
                    key={bank._id}
                    onClick={() => {
                      setValue('bankID', bank._id);
                      setSelectedBank(bank);
                      setSearchTerm(bank.bankName);
                      setFilteredBanks([]);
                    }}
                    className={styles.dropdownItem}
                  >
                    {bank.bankName} ({bank.bankCode})
                  </li>
                ))}
              </ul>
            )}
          </div>
        );

      case 'rg':
      case 'cpf':
      case 'phone':
        return (
          <input
            type="tel"
            {...register(field)}
            className={styles.input}
            placeholder={getPlaceholder(field)}
            autoComplete="off"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');
              const numericValue = pastedText.replace(/\D/g, '');
              setValue(field, numericValue, { shouldValidate: true });
            }}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, '');
              setValue(field, numericValue, { shouldValidate: true });
            }}
          />
        );
      case 'email':
          return (
            <input
              type="text"
              {...register(field)}
              className={styles.input}
              placeholder={getPlaceholder(field)}
              autoComplete="off"
              onKeyPress={(e) => {
                const invalidChars = /[^\w.@-]/g;
                if (invalidChars.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          );
      default:
        return <input type="text" {...register(field)} className={styles.input} placeholder={getPlaceholder(field)} autoComplete="off" />;
    }
  };

  const getLabel = (field) => {
    const labels = {
      name: 'Nome completo',
      birthDate: 'Data de Nascimento',
      gender: 'Gênero',
      rg: 'RG',
      cpf: 'CPF',
      email: 'E-mail',
      phone: 'Telefone',
      password: 'Senha',
      confirmPassword: 'Confirmar Senha',
      instagram: 'Instagram',
      facebook: 'Facebook',
      youtube: 'YouTube',
      tiktok: 'TikTok',
      bankID: 'Banco',
      bankAgency: 'Agência',
      bankAccount: 'Conta',
      pixKey: 'Chave PIX'
    };
    return labels[field] || field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim();
  };  

  const getPlaceholder = (field) => {
    const placeholders = {
      name: 'Ex: João Silva',
      birthDate: 'DD/MM/AAAA',
      rg: 'Somente números',
      cpf: 'Somente números',
      email: 'seu.email@exemplo.com',
      phone: 'DDD + número (somente números)',
      bankAgency: 'Número da agência',
      bankAccount: 'Número da conta',
      pixKey: 'Sua chave PIX'
    };
    return placeholders[field] || '';
  };

  const socialFields = ['instagram', 'facebook', 'youtube', 'tiktok'];

  const baseUrls = {
    instagram: 'https://instagram.com/',
    facebook: 'https://facebook.com/',
    youtube: 'https://youtube.com/@',
    tiktok: 'https://tiktok.com/@'
  };

  const renderSocialMediaFields = () => {
    const formValues = watch();

    return (
      <div className={styles.socialMediaContainer}>
        <p className={styles.socialMediaInfo}>Quanto mais redes sociais você adicionar, maiores serão suas chances de aprovação!</p>
        {socialFields.map((field, index) => (
          <div key={field} className={styles.socialFieldContainer}>
            <div className={styles.socialFieldGroup}>
              <img width={40} src={`/${field}.png`} alt={field} className={styles.icon} />
              <div className={styles.socialInputWrapper}>
                <span className={styles.baseUrl}>{baseUrls[field]}</span>
                <input
                  type="text"
                  {...register(field)}
                  className={`${styles.input} ${styles.socialInput}`}
                  onChange={(e) => {
                    const username = e.target.value.trim();
                    setValue(field, username, { shouldValidate: true });
                    if (username) {
                      clearErrors('instagram');
                    }
                  }}
                  disabled={isInfluencer && ((field === 'instagram' || field === 'tiktok') && formValues[field])}
                  autoComplete="off"
                />
              </div>
            </div>
            {errors[field] && (
              <p className={styles.errorMessage}>{errors[field].message}</p>
            )}
          </div>
        ))}
      </div>
    );
  };  

  useEffect(() => {
    const loadPDF = async () => {
      const pdf = await fetch('/contrato.pdf').then(response => response.arrayBuffer());
      const pdfDoc = await pdfjsLib.getDocument({ data: pdf }).promise;
      const pages = [];
  
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        await page.render({ canvasContext: context, viewport }).promise;
        pages.push(canvas.toDataURL());
      }
  
      setPdfPages(pages);
      setPdfLoaded(true);
    };
  
    loadPDF();
  }, []);

  const handleScroll = useCallback(() => {
    if (contractViewerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contractViewerRef.current;
      const maxScroll = scrollHeight - clientHeight;
      const progress = maxScroll > 0 ? (scrollTop / maxScroll) * 100 : 0;
      setScrollProgress(Math.min(progress, 100));
    }
  }, []); 

  useEffect(() => {
    if (isContractModalOpen && pdfLoaded && contractViewerRef.current) {
      const viewer = contractViewerRef.current;
      viewer.addEventListener('scroll', handleScroll);
      
      return () => {
        viewer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isContractModalOpen, pdfLoaded, handleScroll]);

  
  const renderContractModal = () => {
    return (
      <motion.div 
        className={styles.contractModalOverlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className={styles.contractModalContent}
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
        >
          <h2>Termos e Condições</h2>
          <p>Por favor, revise nossos termos e condições.</p>
          <div 
            className={styles.contractViewer} 
            ref={contractViewerRef}
            onScroll={handleScroll}
          >
            {pdfLoaded ? (
              pdfPages.map((page, index) => (
                <img key={index} src={page} alt={`Página ${index + 1}`} style={{ width: '100%', marginBottom: '10px' }} />
              ))
            ) : (
              <p>Carregando contrato...</p>
            )}
          </div>
          <div className={styles.contractProgressBar}>
            <div className={styles.contractProgressFill} style={{ width: `${scrollProgress}%` }} />
          </div>
          <button 
            className={`${styles.acceptButton} ${scrollProgress >= 90 ? styles.active : ''}`}
            onClick={handleAcceptTerms}
            disabled={scrollProgress < 90}
          >
            Aceito os Termos e Condições
          </button>
          {showScrollError && (
            <p className={styles.errorMessage}>É necessário visualizar todo o contrato para aceitar os termos e condições.</p>
          )}
          <button className={styles.downloadButton} onClick={() => window.open('/contrato.pdf', '_blank')}>
            <FaDownload /> Baixar Contrato
          </button>
        </motion.div>
      </motion.div>
    );
  };

  const renderSuccessMessage = () => (
    <motion.div
      className={styles.successContainer}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <FaCheckCircle className={styles.successIcon} />
      <motion.h2 
        className={styles.successTitle}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Cadastro Realizado com Sucesso!
      </motion.h2>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <p className={styles.successMessage}>
          Obrigado por se interessar em se tornar um influenciador Bodydry Nutrition. Seu cadastro foi recebido e está em análise.
        </p>
        <p className={styles.successSubMessage}>
          Em breve, você receberá um e-mail com mais informações sobre o status da sua aplicação.
        </p>
      </motion.div>
      <motion.button 
        className={styles.returnButton}
        onClick={() => history.push('/login')}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Voltar para Login
      </motion.button>
    </motion.div>
  );

  const handleAcceptTerms = () => {
    if (scrollProgress < 90) {
      setShowScrollError(true);
      return;
    }
    setTermsAccepted(true);
    setShowContractModal(false);
    setShowScrollError(false);
  };
  
  return (
    <div className={styles.cadastroContainer}>
      <img src="/logo.png" alt="Body Dry Logo" className={styles.logo} />
      {cadastroCompleto ? (
        renderSuccessMessage()
      ) : (
      <motion.div
        className={styles.formContainer}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
      >
        <h1 className={styles.title}>Cadastro de Influenciador</h1>
        <div className={styles.progressBar}>
          <div className={styles.progressFill} style={{ width: `${((step + 1) / steps.length) * 100}%` }}></div>
        </div>
        <form onSubmit={(e) => { e.preventDefault(); nextStep(); }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={step}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className={styles.stepTitle}>{steps[step].title}</h2>
              {step === 4 ? (
                renderSocialMediaFields()
              ) : step === 6 ? (
                <div className={styles.termsContainer}>
                  {!termsAccepted && (
                    <>
                    <p>É necessário aceitar os termos e condições para finalizar o cadastro.</p>
                    <button 
                      type="button" 
                      className={styles.viewContractButton} 
                      onClick={() => setShowContractModal(true)}
                    >
                      Ver contrato
                    </button>
                    </>
                  )}
                  {termsAccepted && (
                    <p className={styles.termsAcceptedText}>
                      <span className={styles.checkmark}>✓</span> Eu, <b>{watch('name')}</b>, declaro que li e concordo com os termos e condições estabelecidos no contrato da Bodydry Nutrition.
                    </p>
                  )}
                </div>
              ) : (
                steps[step].fields.map(field => (
                  <div key={field} className={styles.fieldContainer}>
                    <label htmlFor={field} className={styles.label}>
                      {getLabel(field)}
                    </label>
                    {renderField(field)}
                    {errors[field] && <p className={styles.errorMessage}>{errors[field].message}</p>}
                  </div>
                ))                
              )}
            </motion.div>
          </AnimatePresence>
          <div className={styles.buttonContainer}>
            {step > 0 && (
              <button type="button" onClick={prevStep} className={`${styles.button} ${styles.secondary}`}>
                Voltar
              </button>
            )}
            <button 
              type="submit" 
              className={`${styles.button} ${styles.primary}`}
              disabled={isSubmitting}
            >
              {step < steps.length - 1 ? 'Próximo' : 
                isSubmitting ? (
                  <>
                    Aguarde... <Spinner />
                  </>
                ) : 'Finalizar cadastro'
              }
            </button>
          </div>
        </form>
      </motion.div>
      )}
      <AnimatePresence>
        {showContractModal && renderContractModal()}
      </AnimatePresence>
    </div>
  );
};
  
export default Cadastro;